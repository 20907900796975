import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const JustEatSlide3 = () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationItem>
          <PresentationText>
            <MainText>
              <PresentationTitle>Регистрация</PresentationTitle>
              <PresentationTextContent>
                <Point src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687096233/%D0%B3%D0%B0%D0%BB%D0%BE%D1%87%D0%BA%D0%B0_esuy0n.svg" alt="point" />
                Безопасная и быстрая регистрация/авторизация
              </PresentationTextContent>
            </MainText>
            <PresentationImg src='https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687098615/iphone_4_ckjky8.png' />
          </PresentationText>

          <PresentationTextUnder>
            <PresentationImg src='https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687098615/iphone_5_zmg6b8.png' />
            <MainText>
              <PresentationTitle>Корзина</PresentationTitle>
              <PresentationTextContent>
                <Point src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687096233/%D0%B3%D0%B0%D0%BB%D0%BE%D1%87%D0%BA%D0%B0_esuy0n.svg" alt="point" />
                Редактирование позиций
              </PresentationTextContent>
              <PresentationTextContent>
                <Point src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687096233/%D0%B3%D0%B0%D0%BB%D0%BE%D1%87%D0%BA%D0%B0_esuy0n.svg" alt="point" />
                Возможность доставки заказа прямо к двери
              </PresentationTextContent>
            </MainText>

          </PresentationTextUnder>
        </PresentationItem>
      </ContainerPresentation>
    </>

  )

}

export default observer(JustEatSlide3);

const Point = styled.img`
  width: 24px;
  height: 24px;
`;

const MainText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 470px;
`;

const ContainerPresentation = styled.div`
  padding:50px 16px 20px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  align-items: center;
  flex-direction:column;
  max-width:1900px;
`

const PresentationItem = styled.div`
padding-top:130px ;
display: flex;
justify-content: space-between;
align-items:center;
gap: 50px;
@media screen and (max-width: 1470px){
  flex-direction: column;
}
`
const PresentationImg = styled.img`
  width: 100%;
  object-fit:cover;
  max-width:374px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 690px){
    max-width: 240px; 
  }

`
const PresentationText = styled.div`
  width: 100%;
  display: flex;
  /* flex-direction:column; */
  align-items: center;
  gap: 30px;
  @media screen and (max-width: 1470px){
    gap: 160px;
  }
  @media screen and (max-width: 690px){
    gap: 10px;
  }
`
const PresentationTextUnder = styled.div`
  padding-top:100px;
  width: 100%;
  display: flex;
  /* flex-direction:column; */
  align-items: center;
  gap: 30px;
  @media screen and (max-width: 1470px){
    gap: 160px;
  }
  @media screen and (max-width: 690px){
    gap: 10px;
  }
`
const PresentationTitle = styled.div`
  font-family: 'InterSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #333333;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 690px){
    font-size: 22px;
    line-height: 24px;
  }
  @media screen and (max-width: 410px){
    font-size: 16px;
  }
`
const PresentationTextContent = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 44px;
  color: #333333;
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 690px){
  font-size: 20px;
  line-height: 24px;
  }
  @media screen and (max-width: 410px){
    font-size: 16px;
  }
`

