import React from "react";
import { Link, Outlet } from "react-router-dom";
import styled from "styled-components";
// import FooterMobile from "./footerMobile";
import Footer from "./footer";
import IPhone from "./iPhone/iPhone";
import { useTranslation } from 'react-i18next'
import AboutInformation from "./aboutUsInformation";


const Main = () => {
  const { t } = useTranslation()

  return (
    <>
      <MainContainer>
        <MainInforimation>
          <MainLeftSide>
            <LeftSideSwap>
              <FirstString>{t('digital_solution')}</FirstString>
              <SecondString>{t('development_cycle')}</SecondString>
              <ThirdString>{t('unique_solutions')}</ThirdString>
            </LeftSideSwap>
            {/* <OurProjects>
              <BigScreen>
                <div>{t('our_projects')}</div>
                <img src="Main/Arrow.svg" alt="arrow"></img>
              </BigScreen>
            </OurProjects> */}
            <MobileScreen>
              {/* <IPhone bgcolor={'white'} /> */}
              {/* <IphoneMainContainer> */}
              <IphoneMainTitle className="SF_h2">{t('our_projects')}</IphoneMainTitle>
              <AppContainer>
                <AppInfo>
                  <AppImg src="https://res.cloudinary.com/dvuulrr1v/image/upload/v1685271511/Skill%20icons/OLDI.RU_d9vzd6.svg" alt="Oldi"></AppImg>
                  
                  <AppName>
                    <div className="SF_h3">Oldi.ru</div>
                    <div className="SF_body dark-grey">{t('oldi_description')}</div>
                  </AppName>
                </AppInfo>
                <Link to="oldi">
                  <OpenBtn>
                    <span className="buttonText">{t('open')}</span>
                  </OpenBtn>
                </Link>
              </AppContainer>
              <AppContainer>
                <AppInfo>
                  <AppImg src="https://res.cloudinary.com/dghkvb6r0/image/upload/v1684506984/logo/123_wqatlk.svg" alt="123"></AppImg>
                  
                  <AppName>
                    <div className="SF_h3">123.ru</div>
                    <div className="SF_body dark-grey">{t('123_description')}</div>
                  </AppName>
                </AppInfo>
                <Link to="123">
                  <OpenBtn>
                    <span className="buttonText">{t('open')}</span>
                  </OpenBtn>
                </Link>
              </AppContainer>
              <AppContainer>
                <AppInfo>
                  <AppImg src="https://res.cloudinary.com/dxjubrqnd/image/upload/v1654517761/daark/iPhoneImage/AppIcon/PIMS_adjqzt.svg" alt="pims"></AppImg>
                  <AppName>
                    <div className="SF_h3">PIMS</div>
                    <div className="SF_body dark-grey">{t('pims_description')}</div>
                  </AppName>
                </AppInfo>
                <Link to="pims">
                  <OpenBtn>
                    <span className="buttonText">{t('open')}</span>
                  </OpenBtn>
                </Link>
              </AppContainer>
              <AppContainer>
                <AppInfo>
                  <AppImg src="https://res.cloudinary.com/dxjubrqnd/image/upload/v1654517761/daark/iPhoneImage/AppIcon/WoodCoin_ux0sbp.svg" alt="woodkoin"></AppImg>
                  <AppName>
                    <div className="SF_h3">Woodcoin Wallet</div>
                    <div className="SF_body dark-grey">{t('woodcoin_description')}</div>
                  </AppName>
                </AppInfo>
                <Link to="woodcoin">
                  <OpenBtn>
                    <span className="buttonText">{t('open')}</span>
                  </OpenBtn>
                </Link>
              </AppContainer>
              <AppContainer>
                <AppInfo>
                  <AppImg src="https://res.cloudinary.com/dxjubrqnd/image/upload/v1654517761/daark/iPhoneImage/AppIcon/VPN_World_suu08e.svg" alt="VpnWorld"></AppImg>
                  <AppName>
                    <div className="SF_h3">VPN World</div>
                    <div className="SF_body dark-grey">{t('vpn_description')}</div>
                  </AppName>
                </AppInfo>
                <Link to="vpn">
                  <OpenBtn>
                    <span className="buttonText">{t('open')}</span>
                  </OpenBtn>
                </Link>
              </AppContainer>
              <AppContainer>
                <AppInfo>
                  <AppImg src="https://res.cloudinary.com/dxjubrqnd/image/upload/v1658906644/daark/iPhoneImage/AppIcon/Foot_Skill_bqde8n.svg" alt="FootSkill"></AppImg>
                  <AppName>
                    <div className="SF_h3">Foot Skill</div>
                    <div className="SF_body dark-grey">{t('fs_description')}</div>
                  </AppName>
                </AppInfo>
                <Link to="fs">
                  <OpenBtn>
                    <span className="buttonText">{t('open')}</span>
                  </OpenBtn>
                </Link>
              </AppContainer>
              <AppContainer>
                <AppInfo>
                  <AppImg src="https://res.cloudinary.com/dghkvb6r0/image/upload/v1675265273/B2B/b2b56_ibs7df.svg" alt="B2B"></AppImg>
                  <AppName>
                    <div className="SF_h3">B2B OLDI.RU</div>
                    <div className="SF_body dark-grey">{t('b2b_description')}</div>
                  </AppName>
                </AppInfo>
                <Link to="b2b">
                  <OpenBtn>
                    <span className="buttonText">{t('open')}</span>
                  </OpenBtn>
                </Link>
              </AppContainer>
              <AppContainer>
                <AppInfo>
                  <AppImg src="https://res.cloudinary.com/dghkvb6r0/image/upload/v1675535830/bitstorage/bit_logo_eroyyh.svg" alt="BITSTORAGE"></AppImg>
                  <AppName>
                    <div className="SF_h3">BITSTORAGE</div>
                    <div className="SF_body dark-grey">{t('bs_description')}</div>
                  </AppName>
                </AppInfo>
                <Link to="bs">
                  <OpenBtn>
                    <span className="buttonText">{t('open')}</span>
                  </OpenBtn>
                </Link>
              </AppContainer>
              <AppContainer>
                <AppInfo>
                  <AppImg src="https://res.cloudinary.com/dghkvb6r0/image/upload/v1675684754/grainrus/grain_logo_ycgmtp.svg" alt="Grainrus"></AppImg>
                  <AppName>
                    <div className="SF_h3">Grainrus</div>
                    <div className="SF_body dark-grey">{t('grainrus_description')}</div>
                  </AppName>
                </AppInfo>
                <Link to="grainrus">
                  <OpenBtn>
                    <span className="buttonText">{t('open')}</span>
                  </OpenBtn>
                </Link>
              </AppContainer>
              <AppContainer>
                <AppInfo>
                  <AppImg src="https://res.cloudinary.com/dghkvb6r0/image/upload/v1675872666/logo/just_oesym6.svg" alt="Just Eat"></AppImg>
                  <AppName>
                    <div className="SF_h3">Just Eat</div>
                    <div className="SF_body dark-grey">{t('just_description')}</div>
                  </AppName>
                </AppInfo>
                <Link to="just">
                  <OpenBtn>
                    <span className="buttonText">{t('open')}</span>
                  </OpenBtn>
                </Link>
              </AppContainer>

              <Outlet />
              {/* </IphoneMainContainer> */}
            </MobileScreen>
          </MainLeftSide>
          <MainRightSide>
            <IPhone bgcolor={'white'} />
          </MainRightSide>
        </MainInforimation>
        <AboutInformation></AboutInformation>
      </MainContainer>
      {/* <FooterMobile /> */}
      <Footer></Footer>
    </>

  )
}


const MainInforimation = styled.div`
    min-height: calc(100vh - 80px);
    margin: 80px auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    z-index: 2;



    @media screen and (max-width: 767px){
        width: 100%;
        /* justify-content: center; */
        min-height: 100vh;
        margin-top: 60px;
        justify-content: center;
        align-items: flex-start;
    }
`

const MainContainer = styled.div`
    width: 75%;
    max-width: 1100px;
    /* height: 90vh; */
    min-height: calc(100vh - 80px);
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    overflow: hidden;


    @media screen and (max-width: 767px){
        width: 100%;
        /* justify-content: center; */
        min-height: 100vh;
        margin-top: 60px;
        justify-content: center;
        align-items: flex-start;
    }
`

const MainLeftSide = styled.div`
    width: 45%;
    height: fit-content;
    @media screen and (max-width: 767px){
        width: 100%;
    }
`

const LeftSideSwap = styled.div`
    /* position: fixed; */
    width: 100%;
    /* animation: positionTop 0.8s ease-in-out 3.58s 1 normal both; */

    @media screen and (max-width: 767px){
        display: none;
    }
`

const FirstString = styled.h1`
    color: black;
    /* animation: mainPageFirst 0.18s ease-in 1s 1 normal both; */
`

const SecondString = styled.h1`
    /* animation: mainPageSecond 1.2s ease-in 1.18s 1 normal both; */
`

const ThirdString = styled.h1`
    /* animation: mainPageSecond 1.2s ease-in 2.38s 1 normal both; */
`

const OurProjects = styled.h1`
    /* position: fixed; */
    animation: positionBottom 0.6s ease-in-out 3.8s 1 normal both;

    @media screen and (max-width: 767px){
        animation: bottomMobile 0.6s ease-in-out 3.8s 1 normal both;

    }
`

const MainRightSide = styled.div`
    /* position: fixed;
    top: 90px;
    right: 23%; */
    @media screen and (max-width: 767px){
        display: none;
    }

    @media screen and (min-width: 1440px)  and (max-width: 2560px){
        right: 30%;
    }
`

const BigScreen = styled.div`
    @media screen and (max-width: 767px){
        display: none;
    }
 `

const MobileScreen = styled.div`
    margin: 0px auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (min-width: 767px){
        display: none;
    }
    @media screen and (max-width: 767px){
        animation: bottomMobile 0.6s ease-in-out 0.3s 1 normal both;
        zoom: 1.25;
    }
 `

// const IphoneMainContainer = styled.div`
//     width: 100%;
//     height: fit-content;
//     /* margin-left: 8px; */
//     margin: 0px auto;
//     background-color: white;
//     display: flex;
//     flex-direction: column;
//     justify-content: flex-start;
//     align-items: center;

// `

const IphoneMainTitle = styled.div`
    width: 100%;
    padding: 11px 0px;
    text-align: center;
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.3);
    font-size: 35px;
    font-weight: 500;
`

const AppContainer = styled.div`
    width: 95%;
    height: fit-content;
    padding: 16px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--LightGrey);
`

const AppInfo = styled.div`
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`

const AppImg = styled.img`
    width: 100px;
    height: 100px;
`

const AppName = styled.div`
    margin: 0px .5em;
    font-size: "SFProText";
`

const OpenBtn = styled.button`
    width: 150px;
    height: 40px;
    color: var(--Blue);
    background-color: var(--LightGrey);
    border-radius: 24px;
    border: unset;
    cursor: pointer;

    span{
        text-transform: uppercase;
        @media screen and (max-width: 410px){
          font-size: 14px;
    }
    }
    @media screen and (max-width: 410px){
      width: 100px;
    }
`

export default Main;