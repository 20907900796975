import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Footer from '../footer';
import BitstorageSlide1 from './bitstorage/bitstorageSlide1';
import BitstorageSlide2 from './bitstorage/bitstorageSlide2';
import BitstorageSlide3 from './bitstorage/bitstorageSlide3';
import BitstorageSlide4 from './bitstorage/bitstorageSlide4';
import BitstorageSlide5 from './bitstorage/bitstorageSlide5';

const OldiPresentation = () => {
  return (
    <>
      <PresentationWrapper>
        <BitstorageSlide1 />
        <BitstorageSlide2 />
        <BitstorageSlide3 />
        <BitstorageSlide4 />
        <BitstorageSlide5 />
      </PresentationWrapper>
      <Footer />
    </>

  )

}

export default observer(OldiPresentation);

const PresentationWrapper = styled.div`

  background: #F5F5F5;
  margin:0 auto;
  display: flex;
  flex-direction:column;
  align-items: center;
`


