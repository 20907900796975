import React from "react";
import styled from "styled-components";
import { team_map } from "../assets/teamRole/roleData";
import { ourPrize } from "../assets/teamRole/ourPrize";




const AboutUsInformation = () => {


  
  return (
    
    

    <AboutUsContainer>
      <AboutUsTitle>
        <AboutUsText>О нас </AboutUsText>
        <AboutUsArrow src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685106074/arrow-down_ubryjn.svg' />
      </AboutUsTitle>
      <MainInfo>
        <MainTitle>Мы работаем с 2017 года. Нами создано более  10 крупных проектов в различных сферах. Наша команда состоит из 20 высококлассных программистов, которые могут создать проекты любой сложности.
        </MainTitle>
      </MainInfo>
      {team_map
        .map((item) => {
          return (
            <TeamContent>
              <TeamContainer>
                <RoleContainer>
                  <RoleName>{item.role}</RoleName>
                </RoleContainer>
                <RoleContainer>
                  {
                    item.user?.map((item, i) =>

                      <RolePerson>
                        <PersonAvatar src={item.avatar} alt="avatar" />
                        <PersonName>{item.name}</PersonName>
                      </RolePerson>

                    )
                  }
                </RoleContainer>
                <MainInformation>
                  <RoleItems>
                    {/* <RoleName>{item.role}</RoleName> */}
                    <SkillsTitle>Информация</SkillsTitle>
                    <RoleDescription>
                      {item.descriptionRole}
                    </RoleDescription>
                    {/* <RolePerson> */}
                    {/* <PersonAvatar src={item.avatar} alt="avatar" /> */}
                    {/* <PersonName>{item.name}</PersonName> */}
                    {/* <PersonName>{item.surname}</PersonName> */}

                    {/* </RolePerson> */}
                  </RoleItems>
                  <SkillsItems>
                    <SkillsTitle>Навыки: </SkillsTitle>
                    <SkillsDescriptionList>
                      <SkillsDescription>
                        {item.skills.map(el => (
                          <SkillsItem>{el}</SkillsItem>
                        ))}
                      </SkillsDescription>
                    </SkillsDescriptionList>
                  </SkillsItems>
                </MainInformation>

              </TeamContainer>
            </TeamContent>
          )
        })}
      <OurPrize>
        <AboutUsTitle>
          <AboutUsText>Наши заслуги </AboutUsText>
          <AboutUsArrow src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685106074/arrow-down_ubryjn.svg' />
        </AboutUsTitle>
        <InfoBlock>
          <MainImg>
            <Img src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687420318/OLDI_-_%D0%B1%D0%B5%D0%BB%D0%B0%D1%8F_rl2yzk.png" alt="image" />
          </MainImg>
          <MainImg>
            <Img src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687713914/%D0%BD%D0%B0%D0%B3%D1%80%D0%B0%D0%B4%D0%B02_oismux.png" alt="image" />
          </MainImg>
          <MainBlock>
            {ourPrize
              .map((item, i) =>

                <MainText>
                  {
                    item.prize?.map((item, i) =>
                      <MainInfoPrize>
                        <PointImg src={item.vector} alt="image" />
                        <Title>{item.title}</Title>
                      </MainInfoPrize>

                    )
                  }
                </MainText>

              )
            }

          </MainBlock>
        </InfoBlock>
      </OurPrize>

    </AboutUsContainer>

  )
}

const MainTitle = styled.span`
  font-size: 24px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  text-align: justify;
  @media screen and (max-width: 690px){
    font-size: 22px;
    text-align: center;
    ;
  }
`;

const MainInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px;
  @media screen and (max-width: 1060px){
     max-width: 780px;   
  }
`;

const MainInformation = styled.div`
  display: flex;
  flex-direction: column;
  
`;
const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  @media screen and (max-width: 690px){
    
  }
  
`;
const PersonName = styled.span`
  font-family: 'InterSemibold';
  font-weight: normal;
  margin: 4px 0px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #9E9E9E;
  max-width: 190px;
  text-align: center;
`
const PersonAvatar = styled.img`
  width: 190px;
  height: 190px;
  margin-bottom:-7px;
  

`
const RolePerson = styled.div`
  display: flex;
  flex-direction:column;
  align-items: center;
  margin-top:-8px;

`
const RoleContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* max-width: 220px; */
  /* gap: 20px; */

`
const TeamContent = styled.div`
  margin-top:60px; 
  width:100%;
`

const SkillsItem = styled.div`
  /* margin-bottom:20px; */
  @media screen and (max-width: 690px){
    min-width: 150px;
  }
`
const SkillsDescriptionList = styled.div`
  display: flex;
  flex-direction:column;
  gap: 20px;
  
`
const SkillsDescription = styled.div`
  display: flex;
  /* flex-wrap: wrap; */
  flex-direction: column;
  gap: 30px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000; 
  max-width:880px;
  @media screen and (max-width: 1060px){
     max-width: 780px;   
  }
  @media screen and (max-width: 690px){
    flex-wrap: wrap;
    flex-direction: row;
  }
  
  
`
const SkillsTitle = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  min-width: 102px;
  color: #000000;
  
`

const SkillsItems = styled.div`
  padding: 30px 0 0 0 ;
  display: flex;
  gap: 30px;
  /* justify-content: space-between; */
  width:100%;
`
const RoleDescription = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  max-width: 880px;
  text-align: justify;
  @media screen and (max-width: 1060px){
     max-width: 780px;   
  }
  
`
const RoleName = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
  /* max-width:110px; */
`
const RoleItems = styled.div`
  display: flex;
  /* justify-content: space-between; */
  gap: 30px;
  width:100%;
  padding: 0 0 20px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  
`
const TeamContainer = styled.div`
  display: flex;
  flex-direction:column;
  width:100%;
  gap: 60px;
`

const AboutUsContainer = styled.div`
  /* padding:100px 16px ; */
  display: flex;
  justify-content: center;
  flex-direction:column;
  align-items:center;
  /* width:100%; */
  max-width: 1000px;
  margin: 0 auto;
  margin-bottom: 200px;
  /* overflow: scroll; */
  gap: 60px;
  @media screen and (max-width: 960px){
     width: calc( 100% - 80px);
     gap: 40px;  
  }
  

`
const AboutUsTitle = styled.div`
  display: flex;
  justify-content: center;
  flex-direction:column;
  align-items:center;
  gap:20px;
  margin-bottom: -80px;

`
const AboutUsText = styled.span`
  font-family: 'InterSemibold';
  font-weight: 400;
  font-size: 40px;
  width:100%;
`

const AboutUsArrow = styled.img`
`

const MainImg = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const Img = styled.img`
  width: 100%;
  @media screen and (max-width: 690px){
    /* width: 50%; */
  }
`
const MainBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 1000px;
  @media screen and (max-width: 960px){
    max-width: 580px;  
  }
  @media screen and (max-width: 690px){
    max-width: 460px;
  }
  
`
const MainText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  
  
`
const PointImg = styled.img`
  width: 24px;
  height: 24px;
`
const Title = styled.span`
  font-size: 16px;
  font-weight: 400;
  font-family: 'Inter';
  font-style: normal;

`
const OurPrize = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 100px;
  
  
`
const MainInfoPrize = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); 
`

export default AboutUsInformation
