import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const BitstorageSlide4 = () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationItem>
          <PresentationText>
            <PresentationTitle>Страница торгового терминала</PresentationTitle>
          </PresentationText>
          <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685314498/bitstorageslide4_t5y5ib.svg' />
        </PresentationItem>
      </ContainerPresentation>
    </>

  )

}

export default observer(BitstorageSlide4);

const ContainerPresentation = styled.div`
  padding:50px 16px 20px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  align-items: center;
  flex-direction:column;
  max-width:1900px;
`

const PresentationItem = styled.div`
padding-top:130px ;
display: flex;
flex-direction:column;
align-items:center;
gap: 50px;
`
const PresentationImg = styled.img`
  width: 100%;
  object-fit:cover;
  max-width:1000px;
  display: flex;
  justify-content: center;

`
const PresentationText = styled.div`
  max-width:1700px;
  display: flex;
  flex-direction:column;
  gap: 30px;
`
const PresentationTitle = styled.div`
  font-family: 'InterSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #333333;
  display: flex;
  justify-content: center;
`
const PresentationTextContent = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 44px;
  color: #333333;
`


