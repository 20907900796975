import React from "react";
import { useTranslation } from 'react-i18next';
import { Link, Outlet } from "react-router-dom";
import styled from "styled-components";
const IphoneMain = () => {

  const { t } = useTranslation();

  return (
    <IphoneMainContainer>
      <IphoneMainTitle className="SF_h2">{t('our_projects')}</IphoneMainTitle>
      <AppItems>
      <AppContainer>
        <AppInfo>
            <img src="https://res.cloudinary.com/dvuulrr1v/image/upload/v1685271511/Skill%20icons/OLDI.RU_d9vzd6.svg" alt="Oldi"></img>
          <AppName>
            <div className="SF_h3">Oldi.ru</div>
            <div className="SF_body dark-grey">{t('oldi_description')}</div>
          </AppName>
        </AppInfo>
        <Link to="oldi">
          <OpenBtn>
            <span className="buttonText">{t('open')}</span>
          </OpenBtn>
        </Link>
      </AppContainer>
      <AppContainer>
        <AppInfo>
            <img src="https://res.cloudinary.com/dghkvb6r0/image/upload/v1684506984/logo/123_wqatlk.svg" alt="123"></img>
          <AppName>
            <div className="SF_h3">123.ru</div>
            <div className="SF_body dark-grey">{t('123_description')}</div>
          </AppName>
        </AppInfo>
        <Link to="123">
          <OpenBtn>
            <span className="buttonText">{t('open')}</span>
          </OpenBtn>
        </Link>
      </AppContainer>
      <AppContainer>
        <AppInfo>
          <img src="https://res.cloudinary.com/dghkvb6r0/image/upload/v1675535830/bitstorage/bit_logo_eroyyh.svg" alt="BITSTORAGE"></img>
          <AppName>
            <div className="SF_h3">BITSTORAGE</div>
            <div className="SF_body dark-grey">{t('bs_description')}</div>
          </AppName>
        </AppInfo>
        <Link to="bs">
          <OpenBtn>
            <span className="buttonText">{t('open')}</span>
          </OpenBtn>
        </Link>
      </AppContainer>
      <AppContainer>
        <AppInfo>
          <img src="https://res.cloudinary.com/dxjubrqnd/image/upload/v1654517761/daark/iPhoneImage/AppIcon/WoodCoin_ux0sbp.svg" alt="woodkoin"></img>
          <AppName>
            <div className="SF_h3">Woodcoin Wallet</div>
            <div className="SF_body dark-grey">{t('woodcoin_description')}</div>
          </AppName>
        </AppInfo>
        <Link to="woodcoin">
          <OpenBtn>
            <span className="buttonText">{t('open')}</span>
          </OpenBtn>
        </Link>
      </AppContainer>
      <AppContainer>
        <AppInfo>
          <img src="https://res.cloudinary.com/dghkvb6r0/image/upload/v1675684754/grainrus/grain_logo_ycgmtp.svg" alt="Grainrus"></img>
          <AppName>
            <div className="SF_h3">Grainrus</div>
            <div className="SF_body dark-grey">{t('grainrus_description')}</div>
          </AppName>
        </AppInfo>
        <Link to="grainrus">
          <OpenBtn>
            <span className="buttonText">{t('open')}</span>
          </OpenBtn>
        </Link>
      </AppContainer>
      <AppContainer>
        <AppInfo>
          <img src="https://res.cloudinary.com/dxjubrqnd/image/upload/v1654517761/daark/iPhoneImage/AppIcon/PIMS_adjqzt.svg" alt="pims"></img>
          <AppName>
            <div className="SF_h3">PIMS</div>
            <div className="SF_body dark-grey">{t('pims_description')}</div>
          </AppName>
        </AppInfo>
        <Link to="pims">
          <OpenBtn>
            <span className="buttonText">{t('open')}</span>
          </OpenBtn>
        </Link>
      </AppContainer>
      <AppContainer>
        <AppInfo>
          <img src="https://res.cloudinary.com/dxjubrqnd/image/upload/v1654517761/daark/iPhoneImage/AppIcon/VPN_World_suu08e.svg" alt="VpnWorld"></img>
          <AppName>
            <div className="SF_h3">VPN World</div>
            <div className="SF_body dark-grey">{t('vpn_description')}</div>
          </AppName>
        </AppInfo>
        <Link to="vpn">
          <OpenBtn>
            <span className="buttonText">{t('open')}</span>
          </OpenBtn>
        </Link>
      </AppContainer>
      <AppContainer>
        <AppInfo>
          <img src="https://res.cloudinary.com/dxjubrqnd/image/upload/v1658906644/daark/iPhoneImage/AppIcon/Foot_Skill_bqde8n.svg" alt="FootSkill"></img>
          <AppName>
            <div className="SF_h3">Foot Skill</div>
            <div className="SF_body dark-grey">{t('fs_description')}</div>
          </AppName>
        </AppInfo>
        <Link to="fs">
          <OpenBtn>
            <span className="buttonText">{t('open')}</span>
          </OpenBtn>
        </Link>
      </AppContainer>
      <AppContainer>
        <AppInfo>
          <img src="https://res.cloudinary.com/dghkvb6r0/image/upload/v1675265273/B2B/b2b56_ibs7df.svg" alt="B2B"></img>
          <AppName>
            <div className="SF_h3">B2B OLDI.RU</div>
            <div className="SF_body dark-grey">{t('b2b_description')}</div>
          </AppName>
        </AppInfo>
        <Link to="b2b">
          <OpenBtn>
            <span className="buttonText">{t('open')}</span>
          </OpenBtn>
        </Link>
      </AppContainer>
      <AppContainer>
        <AppInfo>
          <img src="https://res.cloudinary.com/dghkvb6r0/image/upload/v1675872666/logo/just_oesym6.svg" alt="Just Eat"></img>
          <AppName>
            <div className="SF_h3">Just Eat</div>
            <div className="SF_body dark-grey">{t('just_description')}</div>
          </AppName>
        </AppInfo>
        <Link to="just">
          <OpenBtn>
            <span className="buttonText">{t('open')}</span>
          </OpenBtn>
        </Link>
      </AppContainer>
      </AppItems>
      <Outlet />
    </IphoneMainContainer>
  )
}

const IphoneMainContainer = styled.div`
    width: 98%;
    height: 90%;
    margin-left: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
`

const IphoneMainTitle = styled.div`
    width: 100%;
    padding: 11px 0px;
    text-align: center;
    box-shadow: 0px 0.5px 0px rgba(0, 0, 0, 0.3);
`

const AppItems = styled.div`
    margin-top:1px;
    max-height:770px;
    overflow-y: scroll;
    width: 98%;
    margin-left: 8px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
   &::-webkit-scrollbar{
    width:5px;
    
   }
   &::-webkit-scrollbar-thumb{
    background-color: gray;
    border-radius:20px;
    padding:5px 0;
   }

`

const AppContainer = styled.div`
    width: 90%;
    padding: 8px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid var(--LightGrey);
`

const AppInfo = styled.div`
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    
`

const AppName = styled.div`
    margin: 0px .5em;
    
`

const OpenBtn = styled.button`
    width: 97px;
    height: 24px;
    color: var(--Blue);
    background-color: var(--LightGrey);
    border-radius: 24px;
    border: unset;
    cursor: pointer;

    span {
        text-transform: uppercase;
    }
    
`

export default IphoneMain