import React from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components';

const vpnSlide5 = () => {
  return (
    <Container>
      <MainText>
        <MainTitle>
          <Point src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687096233/%D0%B3%D0%B0%D0%BB%D0%BE%D1%87%D0%BA%D0%B0_esuy0n.svg" alt="point" />
          <Title>Шифрование IP-адресов.</Title>
        </MainTitle>
        <SubTitle>Основная задача VPNWORLD – скрыть ваш IP-адрес от интернет-провайдера и других лиц. Так вы сможете отправлять и получать через интернет информацию, которую не увидит никто, кроме вас и провайдера безопасного соединения.</SubTitle>
      </MainText>
      <MainText>
        <MainTitle>
          <Point src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687096233/%D0%B3%D0%B0%D0%BB%D0%BE%D1%87%D0%BA%D0%B0_esuy0n.svg" alt="point" />
          <Title>Шифрование протоколов.</Title>
        </MainTitle>
        <SubTitle>
          С VPNWORLD вы не оставите следов своей активности (истории поиска и посещений, cookie-файлов). Шифрование cookie-файлов особенно важно, поскольку не позволяет посторонним получать доступ к конфиденциальной информации: личным, платежным и другим данным.
        </SubTitle>
      </MainText>
      <MainText>
        <MainTitle>
          <Point src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687096233/%D0%B3%D0%B0%D0%BB%D0%BE%D1%87%D0%BA%D0%B0_esuy0n.svg" alt="point" />
          <Title>Защита при сбоях.</Title>
        </MainTitle>
        
        <SubTitle>
          В случае сбоя безопасного соединения ваше интернет-подключение также прерывается. VPNWORLD своевременно обнаружит проблему и закроет трафик для предварительно выбранных программ, чтобы снизить риск хищения данных.
        </SubTitle>
      </MainText>
    </Container>
  )
}

export default observer(vpnSlide5)

const MainTitle = styled.div`
  display: flex;
  gap: 10px;
`;

const Point = styled.img`

`;

const Container = styled.div`
  font-family: 'Inter';
  font-style: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1264px;
  flex-direction: column;
  gap: 30px;
  font-size: 28px;
  margin-bottom: 100px;
  @media screen and (max-width: 1470px){
  max-width: 1000px;
  
}
`;
const MainText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 690px){
    width: calc( 100% - 100px);
  }
`;
const Title = styled.span`
  font-family: 'InterSemiBold';
  font-style: normal;
  font-weight: 500;
  @media screen and (max-width: 690px){
    font-size: 20px;
  }
  
`;
const SubTitle = styled.div`
  @media screen and (max-width: 690px){
    font-size: 16px;
  }
`;  