import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Footer from '../footer';
import VPNSlide1 from './vpn/vpnSlide1';
import VPNSlide2 from './vpn/vpnSlide2';
import VPNSlide3 from './vpn/vpnSlide3';
import VPNSlide4 from './vpn/vpnSlide4';
import VPNSlide5 from './vpn/vpnSlide5';

const OldiPresentation = () => {
  return (
    <>
      <PresentationWrapper>
        <VPNSlide1 />
        <VPNSlide2 />
        <VPNSlide3 />
        <VPNSlide4 />
        <VPNSlide5 />
      </PresentationWrapper>
      <Footer />
    </>

  )

}

export default observer(OldiPresentation);

const PresentationWrapper = styled.div`

  background: #F5F5F5;
  margin:0 auto;
  display: flex;
  flex-direction:column;
  align-items: center;
`


