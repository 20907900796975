import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const B2BSlide5 = () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685303865/Presentation%20Oldi%20Dev/vpn/Figma_tjxb5j.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685303863/Presentation%20Oldi%20Dev/vpn/TypeScript_mhuhbr.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275018/Presentation%20Oldi%20Dev/React_Native_e73diw.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685308042/Presentation%20Oldi%20Dev/footskill/axios_b6lkr8.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685313610/php_j0mlls.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685313637/muit_xcaz2k.svg' />
      </ContainerPresentation>
    </>

  )

}

export default observer(B2BSlide5);

const ContainerPresentation = styled.div`
  padding:200px 100px 200px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  flex-wrap:wrap;
  align-items: center;
  gap: 150px;
  max-width:1200px;
  @media screen and (max-width: 1470px){
    max-width: 1000px;
    gap: 50px;
  }
  @media screen and (max-width: 690px){
    gap: 30px;
  }
`

const PresentationImg = styled.img`
  width:auto;
  max-width:1000px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1470px){
    max-width: 150px;
  }
  @media screen and (max-width: 690px){
    max-width: 100px;
  }
`





