import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Footer from '../footer';
import Slide1 from './project123/slide1';
import Slide2 from './project123/slide2';
import Slide3 from './project123/slide3';
import Slide4 from './project123/slide4';
import Slide5 from './project123/slide5';


const OldiPresentation = () => {
  return (
    <>
      <PresentationWrapper>
        <Slide1 />
        <Slide2 />
        <Slide3 />
        <Slide4 />
        <Slide5 />
      </PresentationWrapper>
      <Footer />
    </>

  )

}

export default observer(OldiPresentation);

const PresentationWrapper = styled.div`

  background: #F5F5F5;
  margin:0 auto;
  display: flex;
  flex-direction:column;
  align-items: center;
`


