import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Footer from '../footer';
import OldiSlide1 from './oldiRu/oldiSlide1';
import OldiSlide2 from './oldiRu/oldiSlide2';
import OldiSlide3 from './oldiRu/oldiSlide3';
import OldiSlide4 from './oldiRu/oldiSlide4';
import OldiSlide5 from './oldiRu/oldiSlide5';


const OldiPresentation = () => {
  return (
    <>
      <PresentationWrapper>
        <OldiSlide1 />
        <OldiSlide2 />
        <OldiSlide3 />
        <OldiSlide4 />
        <OldiSlide5 />
      </PresentationWrapper>
      <Footer />
    </>

  )

}

export default observer(OldiPresentation);

const PresentationWrapper = styled.div`

  background: #F5F5F5;
  margin:0 auto;
  display: flex;
  flex-direction:column;
  align-items: center;
`


