import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const VPNSlide4= () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685303863/Presentation%20Oldi%20Dev/vpn/TypeScript_mhuhbr.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685303863/Presentation%20Oldi%20Dev/vpn/Objective-C_noxmot.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685303867/Presentation%20Oldi%20Dev/vpn/Java_xuwkrp.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275018/Presentation%20Oldi%20Dev/React_Native_e73diw.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685303863/Presentation%20Oldi%20Dev/vpn/Rust_b1ygby.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685303864/Presentation%20Oldi%20Dev/vpn/Spring_oyrwwi.svg' />
        {/* <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685303868/Presentation%20Oldi%20Dev/vpn/MobX-state-tree_vrshsr.svg' /> */}
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685303865/Presentation%20Oldi%20Dev/vpn/Figma_tjxb5j.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685303865/Presentation%20Oldi%20Dev/vpn/Electron_rhh2mc.svg' />
      </ContainerPresentation>
    </>

  )

}

export default observer(VPNSlide4);

const ContainerPresentation = styled.div`
  padding:200px 100px 200px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  flex-wrap:wrap;
  align-items: center;
  gap: 150px;
  max-width:1900px;
  @media screen and (max-width: 1470px){
    max-width: 1000px;
    gap: 50px;
  }
  @media screen and (max-width: 690px){
    gap: 30px;
  }

`

const PresentationImg = styled.img`
  width:auto;
  max-width:1000px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1470px){
    max-width: 150px;
  }
  @media screen and (max-width: 690px){
    max-width: 100px;
  }

`

