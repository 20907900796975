export const team_map = [
  {
    role: 'Team Lead',
    // avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1653567197/daark/About/avatars/Tema_jugzdq.png",
    user: [
      {
        avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1653567197/daark/About/avatars/Tema_jugzdq.png",
        name: "Артём Семёнов",
      },
    ],
    // name: 'Артём',
    // surname: 'Семёнов',
    descriptionRole: 'Руководитель группы в ИТ-команде отвечает за управление, координацию и руководство работой группы ИТ-специалистов. Устанавливает цели проекта, назначают задачи членам команды, следит за ходом выполнения, обеспечивают соблюдение стандартов качества и при необходимости предоставляют рекомендации.',
    skills: [
      '1. Программное обеспечение для управления проектами, такое как Jira, Trello или Asana.',
      '2. Репозитории кода, такие как GitHub или Bitbucket, для управления кодом и контроля версий.',
      '3. Средства связи, такие как Slack, Microsoft Teams или Zoom для совместной работы и собраний в команде.',
      '4. Программное обеспечение для виртуализации, такое как VMware, VirtualBox или Docker, для тестирования и развертывания приложений.',
      '5. Инструменты непрерывной интеграции и доставки (CI/CD), такие как Jenkins, Travis CI или CircleCI, для автоматизации процессов разработки программного обеспечения.',
      '6. Программное обеспечение для управления базами данных, такое как MySQL, Oracle или Microsoft SQL Server.',
      '7. Редакторы кода, такие как Visual Studio Code, Atom или Sublime Text, для написания кода.',
      '8. Платформы облачных вычислений, такие как Amazon Web Services (AWS), Microsoft Azure или Google Cloud Platform, для размещения приложений.',
      '9. Инструменты сетевого мониторинга и анализа, такие как Nagios, Wireshark или Zabbix, для устранения неполадок и оптимизации производительности сети.',
      '10. Программное обеспечение безопасности, такое как антивирусные программы, брандмауэры или системы обнаружения вторжений для защиты ИТ-инфраструктуры от киберугроз.'
    ]
  },
  {
    role: 'Project manager',
    user: [
      {
        avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1653567197/daark/About/avatars/Dan_ffynvn.png",
        name: "Данила Ворновицкий",
      },
    ],
    // name: 'Данила',
    // surname: 'Ворновицкий',
    // avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1653567197/daark/About/avatars/Dan_ffynvn.png",
    descriptionRole: 'Менеджер проекта в ИТ-команде отвечает за планирование, организацию и надзор за выполнением ИТ-проектов. Как правило, они несут ответственность за то, чтобы проекты выполнялись вовремя, в рамках бюджета и к удовлетворению заинтересованных сторон. Они также контролируют проектные группы, распределяют ресурсы, управляют рисками и сообщают заинтересованным сторонам о прогрессе.',
    skills: [
      '1. Программное обеспечение для планирования проектов (например, Microsoft Project)',
      '2. Программное обеспечение для управления задачами (например, Asana)',
      '3. Средства коммуникации (например, Slack, Microsoft Teams)',
      '4. Программное обеспечение для отслеживания времени (например, Toggl)',
      '5. Программное обеспечение для управления бюджетом (например, QuickBooks, Xero)',
      '6. Программное обеспечение для отслеживания проблем (например, Jira)',
      '7. Программное обеспечение для управления рисками (например, RiskyProject)',
      '8. Программное обеспечение для управления ресурсами (например, Resource Guru)',
      '9. Инструменты для совместной работы (например, Google Docs, Dropbox)',
      '10. Программное обеспечение для гибкого управления проектами (например, Trello, KanbanFlow)'
    ]
  },
  {
    role: 'Front-end dev',
    user: [
      {
        avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1653567197/daark/About/avatars/Slava_ggj2al.png",
        name: "Вячеслав Нестерцев",
      },
      {
        avatar: "https://res.cloudinary.com/dpwbrp0db/image/upload/v1676045702/DAARK/Mukha.png",
        name: "Мухамед Желихажев", 
      },
      {
        avatar: "https://res.cloudinary.com/dghkvb6r0/image/upload/v1677783563/memoji/%D0%98%D0%B7%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_1_kowe74.png",
        name: "Игорь Гордеев", 
      },
      {
        avatar: "https://res.cloudinary.com/dghkvb6r0/image/upload/v1677783563/memoji/IMG_1565_lknmvz.png",
        name: "Никита Лысенко", 
      },
    ],
    // name: 'Вячеслав',
    // surname: 'Нестерцев',
    // avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1653567197/daark/About/avatars/Slava_ggj2al.png",
    descriptionRole: 'Интерфейсный разработчик отвечает за разработку и реализацию пользовательского интерфейса (UI) и взаимодействия с пользователем (UX) веб-сайта или приложения. Они тесно сотрудничают с дизайнерами, бэкенд-разработчиками и руководителями проектов, чтобы сайт или приложение были функциональными, простыми в использовании и визуально привлекательными. Они используют языки программирования, такие как HTML, CSS и JavaScript, для создания адаптивного дизайна и интерактивных функций.',
    skills: [
      '1. Typescript',
      '2. CSS',
      '3. JavaScript',
      '4. XML',
      '5. React',
      '6. HTML',
      
      
    ]
  },
  {
    role: 'Back-end dev',
    user: [
      // {
      //   avatar: "https://res.cloudinary.com/dpwbrp0db/image/upload/v1675964386/DAARK/IMG_1719_gnicic.png",
      //   name: "Максим Казаков",
      // },
      {
        avatar: "https://res.cloudinary.com/dpwbrp0db/image/upload/v1675964386/DAARK/IMG_1719_gnicic.png",
        name: "Максим Казаков",
      },
      {
        avatar: "https://res.cloudinary.com/dpwbrp0db/image/upload/v1673278186/DAARK/30d40212e2c8811c14117ff71595f86d-sticker_o8ppcg.png",
        name: "Игорь Тонин", 
      },
      {
        avatar: "https://res.cloudinary.com/dghkvb6r0/image/upload/v1677783688/memoji/%D0%90%D0%B1%D0%B4%D0%B5%D0%BB%D1%8C%D1%87%D0%B8%D0%BA_dvl4lk.png",
        name: "Абдель Эльхила", 
      },
      {
        avatar: "https://res.cloudinary.com/dmxoqnqsu/image/upload/v1686257805/IMG_2267_pgsa8s.png",
        name: "Игорь Горобец", 
      },
    ],
    // name: 'Максим ',
    // surname: 'Казаков',
    // avatar: "https://res.cloudinary.com/dpwbrp0db/image/upload/v1675964386/DAARK/IMG_1719_gnicic.png",
    descriptionRole: 'Бэкенд-разработчик в ИТ-команде отвечает за проектирование, создание и поддержку серверной части веб-приложений, включая базу данных, сервер и логику приложения. Они тесно сотрудничают с разработчиками интерфейса и другими членами команды, чтобы обеспечить общую функциональность, масштабируемость и безопасность приложения.',
    skills: [
      '1. Java',
      '2. Python',
      '3. С++',
      '4. Ruby',
      '5. PHP',
      '6. SQL',
      '7. Go',
      '8. PostgreSQL',
    ]
  },
  {
    role: 'Q&A',
    user: [
      {
        avatar: "https://res.cloudinary.com/dghkvb6r0/image/upload/v1677783563/memoji/%D0%98%D0%B7%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_ne9bwv.png",
        name: "Шараф Эльхила",
      },
      
    ],
    // name: 'Шараф  ',
    // surname: 'Эльхила',
    // avatar: "https://res.cloudinary.com/dghkvb6r0/image/upload/v1677783563/memoji/%D0%98%D0%B7%D0%BE%D0%B1%D1%80%D0%B0%D0%B6%D0%B5%D0%BD%D0%B8%D0%B5_ne9bwv.png",
    descriptionRole: 'Внутренний тестер в ИТ-команде отвечает за тестирование функциональности серверных компонентов программного приложения или системы, включая базы данных, API-интерфейсы и другие внутренние службы. Они обеспечивают правильную и эффективную работу серверных служб и беспрепятственную интеграцию с внешним пользовательским интерфейсом. Они также выявляют и сообщают о любых дефектах или проблемах команде разработчиков для решения.',
    skills: [
      '1. Платформа тестирования: Selenium WebDriver или Appium для веб-тестирования и мобильного тестирования соответственно.',
      '2. Язык программирования: Python или JavaScript для написания тестовых сценариев.',
      '3. Инструмент управления тестированием: JIRA или TestRail для управления тестовыми примерами и сообщения об ошибках.',
      '4. Система контроля версий: Git для управления кодом и совместной работы.',
      '5. Инструмент непрерывной интеграции: Jenkins или Travis CI для автоматизации процесса тестирования.',
      '6. Программное обеспечение для виртуализации: Docker для контейнеризации среды тестирования.',
      '7. Эмуляторы браузера или устройства: ChromeDriver или эмулятор Android для тестирования на разных платформах.',
      '8. Selenium для автоматизированного тестирования.',
      '9. JMeter для нагрузочного тестирования.',
      '10. Postman для тестирования API.',
      '11. Bugzilla для отслеживания ошибок и Git для контроля версий.',
    ]
  },
  {
    role: 'DevOps',
    user: [
      {
        avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1653567197/daark/About/avatars/Karim_wcc6on.png",
        name: "Ouazmir Abdelkarim",
      },
      
    ],
    
    descriptionRole: 'Внешний тестировщик в ИТ-команде отвечает за тестирование пользовательского интерфейса и функциональности веб-приложений, гарантируя, что они удобны для пользователя и реагируют на различные устройства и браузеры. Они также могут выполнять функциональное тестирование, регрессионное тестирование и тестирование кросс-браузерной совместимости, чтобы обеспечить высокое качество взаимодействия с пользователем. DevOps отвечает за оптимизацию процесса разработки и развертывания программных приложений, сокращая разрыв между разработчиками программного обеспечения и командами ИТ-операций. Они используют различные инструменты и методы автоматизации, чтобы гарантировать эффективность, надежность и масштабируемость процесса разработки программного обеспечения. DevOps также фокусируется на непрерывной интеграции, тестировании и развертывании, чтобы программные приложения доставлялись клиентам быстрее и с меньшим количеством ошибок.',
    skills: [
      '1. Системы контроля версий, такие как Git.',
      '2. Инструменты непрерывной интеграции и развертывания, такие как Jenkins или Travis CI.',
      '3. Инструменты управления конфигурацией, такие как Puppet или Chef.',
      '4. Технологии контейнеризации, такие как Docker или Kubernetes.',
      '5. Инструменты мониторинга и ведения журнала, такие как стек Nagios или ELK.',
      
    ]
  },
  {
    role: 'IT - Безопасник',
    user: [
      {
        avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1653567197/daark/About/avatars/Shoma_bal70f.png",
        name: "Шамиль Махмудов",
      },
      
    ],
    // name: 'Кирилл',
    // surname: 'Алекcеев',
    // avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1653567197/daark/About/avatars/Kirill_z9eryj.png",
    descriptionRole: 'Инженер по IT - безопасности отвечает за выявление и устранение уязвимостей безопасности в ИТ-системе или сети. Они разрабатывают и реализуют меры безопасности, отслеживают нарушения безопасности, расследуют инциденты безопасности и реагируют на них, а также обучают других членов команды передовым методам обеспечения безопасности.',
    skills: [
      'Инженер по IT - безопасности может использовать стек, состоящий из различных инструментов и технологий, таких как брандмауэры, системы обнаружения вторжений (IDS), системы управления информацией и событиями безопасности (SIEM), сканеры уязвимостей, инструменты тестирования на проникновение, технологии шифрования и платформы аналитики безопасности для защиты и защиты. защитить сеть или систему.',
      
    ]
  },
  {
    role: 'Дизайнер UX/UI',
    user: [
      {
        avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1655806169/daark/About/avatars/Iluha.png",
        name: "Илья Ажбекберов",
      },
      
    ],
    // name: 'Илья    ',
    // surname: 'Ажбекберов',
    // avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1655806169/daark/About/avatars/Iluha.png",
    descriptionRole: 'Дизайнеры UX/UI (пользовательский опыт/пользовательский интерфейс) в ИТ-команде отвечают за разработку и улучшение пользовательского интерфейса программных приложений, чтобы они были интуитивно понятными, простыми в использовании и визуально привлекательными. Они анализируют поведение пользователей, проводят исследования пользователей и создают каркасы и прототипы для улучшения взаимодействия с пользователем. Их цель — сделать программные приложения более удобными и эффективными, что приведет к повышению удовлетворенности пользователей и производительности.',
    skills: [
      '1. Sketch — популярный редактор векторной графики для дизайна интерфейсов.',
      '2. Figma — инструмент для совместной разработки интерфейсов.',
      '3. Adobe XD — инструмент для дизайна интерфейсов с функциями прототипирования.',
      '4. InVision — платформа для прототипирования, совместной работы и рабочих процессов.',
      '5. Axure RP — инструмент для быстрого прототипирования и каркасного моделирования.',
      '6. Balsamiq — инструмент для каркасного моделирования с упором на простоту и удобство использования.',
      '7. Marvel — платформа для прототипирования и совместной работы над дизайном интерфейсов.',
      '8. Zeplin — инструмент совместной работы для дизайна интерфейса и передачи разработки.',
      '9. Principle — инструмент моушн-дизайна для создания интерактивных прототипов.',
      '10. Flinto — инструмент анимации и прототипирования для создания интерактивных дизайнов приложений.',
    ]
  },
  {
    role: 'Архитектор',
    user: [
      
      {
        avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1653567197/daark/About/avatars/Kirill_z9eryj.png",
        name: "Кирилл Алексеев",
      },
      
    ],
    // name: 'Шамиль',
    // surname: 'Махмудов',
    // avatar: "https://res.cloudinary.com/dxjubrqnd/image/upload/v1653567197/daark/About/avatars/Shoma_bal70f.png",
    descriptionRole: 'Архитектор в ИТ-команде обычно проектирует и планирует общую структуру и структуру разрабатываемого программного обеспечения или системы. Они принимают решения о технологиях, шаблонах проектирования и точках интеграции, чтобы гарантировать, что конечный продукт соответствует бизнес-требованиям, масштабируем и удобен в сопровождении. Они также обеспечивают руководство и надзор за командой разработчиков, чтобы убедиться, что архитектура реализована правильно.',
    skills: [
      '1. Microsoft Visio для создания блок-схем и диаграмм для визуализации дизайна продукта.',
      '2. Sketch или Adobe XD для проектирования пользовательских интерфейсов и создания прототипов.',
      '3. JIRA или Trello для управления проектами и совместной работы в команде.',
      '4. Slack или Microsoft Teams для общения в режиме реального времени с членами команды.',
      '5. GitHub или GitLab для контроля версий и совместной работы над кодом.',
      '6. AWS или Azure для развертывания и управления облачными продуктами.',
      '7. Jenkins или Travis CI для непрерывной интеграции и непрерывного развертывания (CI/CD).',
      '8. Splunk для мониторинга и анализа системных журналов и данных.',
      '9. Tableau для визуализации данных и отчетности.',
    ]
  },
]