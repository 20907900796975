import React from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";
import { usePersistentStore } from "../store";
import Footer from "./footer";
import { useTranslation } from 'react-i18next'
import cookies from 'js-cookie'
// import FooterMobile from "./footerMobile";
// import { languages } from "..";

const About = () => {



  const { team } = usePersistentStore();
  const { t } = useTranslation();

  const currentLanguageCode = cookies.get('i18next') || 'ru';
  // const currentLanguage = languages.find((l) => l.code === currentLanguageCode);


  // console.log(getSnapshot(team.team));

  return (
    <>

      <AboutContainer>
        <AboutTitle>{t('team')}</AboutTitle>
        <AboutTeam>
          {
            team.team?.map((e, i) =>
              <TeamContainer key={i}>
                <TeamContent>
                  <TeamImg src={e.avatar} alt="avatar" />
                  <TeamInner>
                    <TeamItems>
                      <TeamName className="primaryButtonText">{currentLanguageCode === 'ru' ? e.name : e.nameENG}</TeamName>
                      <TeamPosition className="tertiaryButtonText">/{e.position}</TeamPosition>
                    </TeamItems>
                    <TeamStack>
                      {
                        e.stack?.map((f, i) =>
                          <TeamStackImg src={f.img} alt="stack-img" key={i} />
                        )
                      }
                    </TeamStack>
                  </TeamInner>
                </TeamContent>
              </TeamContainer>
            )
          }
        </AboutTeam>
      </AboutContainer>
      {/* <FooterMobile /> */}
      <Footer />
    </>

  )
}

export default observer(About)


const AboutTitle = styled.h1`
 text-align: center;
 @media screen and (max-width: 567px){
  font-size: 30px;        
}
`
const TeamItems = styled.div`
  display: flex;
  margin-top: 20px;
  @media screen and (max-width: 967px){
    align-items: center;
    justify-content: center;
  }
`
const TeamContent = styled.div`
  display: flex;
  @media screen and (max-width: 967px){
    flex-direction: column;
    align-items: center;
    width: calc( 100% - 40px);
    margin-left: 50px;
  }
`
const TeamInner = styled.div`
  display: flex;
  flex-direction:column;
  gap: 15px;
`

const AboutContainer = styled.div`
    /* min-width: 1000px; */
    width: 70%;
    max-width: 1000px;
    min-height: 100vh;
    /* height: 100vh; */
    margin: 0px auto;
    margin-top: 60px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    text-align: center;
    @media screen and (max-width: 967px){
      
    }
`

const AboutTeam = styled.div`
/*     width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap; */

    @media screen and (max-width: 767px){
        flex-direction: column;
    }
`

const TeamContainer = styled.div`
    /* width: 200px; */
    /* height: 200px; */
    /* margin: 1em 2em; */
    display: flex;
    flex-direction: column;
    /* gap: 50px; */
    /* align-items: flex-start; */
    margin-bottom:50px;
    @media screen and (max-width: 767px){
        
    }
`

const TeamImg = styled.img`
    width: 200px;
    height: 200px; 

    @media screen and (max-width: 767px){
        
    }
`

const TeamName = styled.div`
  font-weight: normal;
  margin: 4px 0px;
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 19px;
  color: #000000;
  @media screen and (max-width: 767px){
    font-size: 16px;
  }
  @media screen and (max-width: 467px){
    font-size: 14px;
  }
`

const TeamPosition = styled.div`
    margin: 4px 0px;
    color:gray;
    font-size: 20px;
    @media screen and (max-width: 767px){
    font-size: 16px;
    }
    @media screen and (max-width: 467px){
    font-size: 14px;
    }
`

const TeamStack = styled.div`
    /* width: 100%; */
    height: fit-content;
    margin-top: 4px; 
    display: flex;
    flex-flow: row;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 20px;
    max-width: 700px;
    /* min-width:680px; */

    @media screen and (max-width: 767px){
        margin-top: 15px
    }
`

const TeamStackImg = styled.img`
    width: 80px;
    height: 80px;
    margin: 5px 10px 5px -12px;
    /* box-shadow: 0px 4px 20px rgba(9, 9, 12, 0.1); */
    border-radius: 3px;

    @media screen and (max-width: 767px){
        
    }
`