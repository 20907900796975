import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const PimsSlide2 = () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationItem>
          <PresentationImg src='https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687433158/Group_1697_eq0v3s.svg' />
          <PresentationText>
            <PresentationTitle>PIMS</PresentationTitle>
            <PresentationTextContent>
              Мы создали кросс-платформенное решение для клиентов PIMS, которое поможет избежать очередей на кассах и снизить расходы на доставку продукции.
            </PresentationTextContent>
            <PresentationTextContent>
              Наша команда разработала с нуля мобильное приложение под Android и iOS, связанное со своей панелью администратора. Через админ-панель сотрудники ресторана редактируют позиции меню, следят за статусами заказа и контролируют доставку напитков. Для оформление доставки мы интегрировали сервисы Яндекса, а для приема оплат была проведена интеграция мобильного SDK банка. Были настроены рекламные блоки и функционал push-уведомлений.
            </PresentationTextContent>
            <PresentationTextContent>
              Программный комплекс разрабатывался нами в течение 7-ми месяцев. Сейчас вы можете скачать результаты нашей работы в плэй-маркетах вашего смартфона.
            </PresentationTextContent>
          </PresentationText>
        </PresentationItem>
      </ContainerPresentation>
    </>

  )

}

export default observer(PimsSlide2);

const ContainerPresentation = styled.div`
  padding:50px 16px 20px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  align-items: center;
  flex-direction:column;
  max-width:1900px;
`

const PresentationItem = styled.div`
/* padding-top:130px ; */
display: flex;
justify-content: space-between;
align-items:center;
gap: 50px;
@media screen and (max-width: 1470px){
  flex-direction: column-reverse;
}
`
const PresentationImg = styled.img`
  width: 100%;
  object-fit:cover;
  max-width:1000px;
  display: flex;
  justify-content: center;

`
const PresentationText = styled.div`
  max-width:965px;
  display: flex;
  flex-direction:column;
  gap: 30px;
`
const PresentationTitle = styled.div`
  font-family: 'InterSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #333333;
  display: flex;
  justify-content: center;
`
const PresentationTextContent = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 44px;
  color: #333333;
`





