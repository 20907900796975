import Header from './components/header.js'
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from 'react-router-dom';
import Contacts from './components/contacts.js';
import Calculation from './components/calculation/calculation.js';
import About from './components/about.js';
import Main from './components/main.js';
// import Pims from './components/projects/pims.js';
// import Woodcoin from './components/projects/woodcoin.js';
// import Vpn from './components/projects/vpn.js';
import FooterMobile from './components/footerMobile.js';
// import FootSkill from './components/projects/fs.js';
// import ScrollToTop from './ScrollToTop.js';
// import B2B from './components/projects/b2b.js';
// import Bitstorage from './components/projects/bitstorage.js';
// import Grainrus from './components/projects/grainrus.js';
// import Just from './components/projects/just.js';
import PimsPresentation from './components/project presentation/pimsP.js';
import B2BPresentation from './components/project presentation/b2bP.js';
import BitstoragePresentation from './components/project presentation/bitstorageP.js';
import FootSkillPresentation from './components/project presentation/fsP.js';
import GrainrusPresentation from './components/project presentation/grainrusP.js';
import JustPresentation from './components/project presentation/justP.js';
import VPNPresentation from './components/project presentation/vpnP.js';
import WoodcoinPresentation from './components/project presentation/woodcoinP.js';
import Presentanion123 from './components/project presentation/123P.js';
import OldiPresentation from './components/project presentation/oldiP.js';

const App = () => {

  // let location = useLocation();

  return (
    <div>
      <BrowserRouter basename='/'>
        {/* <ScrollToTop> */}
        <Header />
        <Routes>
          <Route path='/' element={<Main />} exact>
            {/* <Route path="pims" element={<Pims />} /> */}
          </Route>
          <Route path="/pims" element={<PimsPresentation />} />
          <Route path="/woodcoin" element={<WoodcoinPresentation />}></Route>
          <Route path='/vpn' element={<VPNPresentation />}></Route>
          <Route path='/fs' element={<FootSkillPresentation />}></Route>
          <Route path="/contacts" element={<Contacts />} />
          <Route path='/calculation' element={<Calculation />} />
          <Route path='/about' element={<About />} />
          <Route path='/b2b' element={<B2BPresentation />} />
          <Route path='/bs' element={<BitstoragePresentation />} />
          <Route path='/grainrus' element={<GrainrusPresentation />} />
          <Route path='/just' element={<JustPresentation />} />
          <Route path='/123' element={<Presentanion123 />} />
          <Route path='/oldi' element={<OldiPresentation />} />
        </Routes>
        {/* </ScrollToTop> */}

        <FooterMobile />
      </BrowserRouter>
    </div>
  );
}

export default App;
