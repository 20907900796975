import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Footer from '../footer';
import Grainrusslide1 from './grainrus/grainrusslide1';
import Grainrusslide2 from './grainrus/grainrusslide2';
import Grainrusslide3 from './grainrus/grainrusslide3';
import Grainrusslide4 from './grainrus/grainrusslide4';
import Grainrusslide5 from './grainrus/grainrusslide5';

const OldiPresentation = () => {
  return (
    <>
      <PresentationWrapper>
        <Grainrusslide1 />
        <Grainrusslide2 />
        <Grainrusslide3 />
        <Grainrusslide4 />
        <Grainrusslide5 />
      </PresentationWrapper>
      <Footer />
    </>

  )

}

export default observer(OldiPresentation);

const PresentationWrapper = styled.div`

  background: #F5F5F5;
  margin:0 auto;
  display: flex;
  flex-direction:column;
  align-items: center;
`

