import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Footer from '../footer';
import B2BSlide1 from './b2b/b2bSlide1';
import B2BSlide2 from './b2b/b2bSlide2';
import B2BSlide3 from './b2b/b2bSlide3';
import B2BSlide4 from './b2b/b2bSlide4';
import B2BSlide5 from './b2b/b2bSlide5';
import B2BSlide6 from './b2b/b2bSlide6';

const OldiPresentation = () => {
  return (
    <>
      <PresentationWrapper>
        <B2BSlide1 />
        <B2BSlide2 />
        <B2BSlide3 />
        <B2BSlide4 />
        <B2BSlide5 />
        <B2BSlide6 />
      </PresentationWrapper>
      <Footer />
    </>

  )

}

export default observer(OldiPresentation);

const PresentationWrapper = styled.div`

  background: #F5F5F5;
  margin:0 auto;
  display: flex;
  flex-direction:column;
  align-items: center;
`


