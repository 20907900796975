import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const OldiSlide2 = () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationItem>
          <PresentationText>
            <PresentationTitle>
              OLDI.RU: Дистрибьютор электроники, компьютерных комплектующих и бытовой техники
            </PresentationTitle>
            <PresentationTextContent>
              OLDI.RU — это бизнес-модель, при которой одна компания продаёт продукцию другим компаниям. Данный сайт был сделан для того, чтобы упростить процесс взаимодействия клиента с компанией OLDI. На данном сайте можно ознакомиться с ассортиментом товаров, распределенным по категориям, отсортировать товары по многим характеристикам, начиная от срока гарантии и заканчивая наименованием товара. Добавлять товары в корзину и отслеживать доставку товара.
            </PresentationTextContent>
            <PresentationTextContent>
              Мы создали с нуля сайт, реализовали удобный каталог товаров, поиск по сайту, сортировку по множественным параметрам. Также на сайте имеется возможность создать профиль клиента, для просмотра каталога товаров.
            </PresentationTextContent>
            <PresentationTextContent>
              Данный сайт разрабатывался в течение 12-х месяцев. Сейчас вы можете увидеть результаты нашей работы на сайте: OLDI.RU
            </PresentationTextContent>
          </PresentationText>
          <PresentationImg src='https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687105146/MacBook_Pro_16_3_iu7jrx.png' />
        </PresentationItem>
      </ContainerPresentation>
    </>

  )

}

export default observer(OldiSlide2);

const ContainerPresentation = styled.div`
  padding:50px 16px 20px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  align-items: center;
  flex-direction:column;
  max-width:1900px;
`

const PresentationItem = styled.div`
/* padding-top:130px ; */
margin-top: 20px;
display: flex;
flex-direction:column;
align-items:center;
gap: 50px;
`
const PresentationImg = styled.img`
  width: 100%;
  /* object-fit:cover; */
  max-width:1000px;
  display: flex;
  justify-content: center;

`
const PresentationText = styled.div`
  max-width:1700px;
  display: flex;
  flex-direction:column;
  
  gap: 30px;
  @media screen and (max-width: 1470px){
  max-width: 1000px;
  align-items: center;
}
`
const PresentationTextUnder = styled.div`
  padding-top:100px;
  max-width:500px;
  display: flex;
  flex-direction:column;
  gap: 30px;
`
const PresentationTitle = styled.div`
  font-family: 'InterSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #333333;
  display: flex;
  justify-content: center;
`
const PresentationTextContent = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 44px;
  color: #333333;
`