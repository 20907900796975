import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const FootSkillSlide2 = () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationItem>
          <PresentationImg src='https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687434501/Group_1697_t9bzxg.svg' />
          <PresentationText>
            <PresentationTitle>FOOT SKILL: Футбольный тренер</PresentationTitle>
            <PresentationTextContent>
              Данный кейс интересен тем, что мы разработали для наших друзей из Foot Skill собственную IT-инфраструктуру, которая включает в себя: web-приложение для учеников школы, web-интерфейс для тренеров, нативное приложение под iOS, нативное приложение под Android.
            </PresentationTextContent>
            <PresentationTextContent>
              Foot Skill - первая школа футбольной техники на юге России. Сегодня родители и ученики школы могут отслеживать статистику футбольного тестирования и сравнивать ее с успехами сверстников. Реализована программа лояльности и достижений, а расписание школы можно легко посмотреть в мобильном приложении.
            </PresentationTextContent>
            <PresentationTextContent>
              Наш сервис прост в использовании и доступен на всех устройствах. Решение разрабатывалось нами в течение 6-ти месяцев. Сейчас вы можете скачать результаты нашей работы в плэй-маркетах вашего телефона или посмотреть на сайте: krasnodar-fs.ru
            </PresentationTextContent>
          </PresentationText>
        </PresentationItem>
      </ContainerPresentation>
    </>

  )

}

export default observer(FootSkillSlide2);

const ContainerPresentation = styled.div`
  padding:50px 16px 20px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  align-items: center;
  flex-direction:column;
  max-width:1900px;
`

const PresentationItem = styled.div`
padding-top:80px ;
display: flex;
justify-content: space-between;
align-items:center;
gap: 50px;
@media screen and (max-width: 1470px){
  flex-direction: column-reverse;
}
`
const PresentationImg = styled.img`
  width: 100%;
  object-fit:cover;
  max-width:1000px;
  display: flex;
  justify-content: center;

`
const PresentationText = styled.div`
  max-width:965px;
  display: flex;
  flex-direction:column;
  gap: 30px;
`
const PresentationTitle = styled.div`
  font-family: 'InterSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #333333;
  display: flex;
  justify-content: center;
`
const PresentationTextContent = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 44px;
  color: #333333;
`

