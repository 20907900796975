import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Footer from '../footer';
import FootSkillSlide1 from './footskill/footSkillSlide1';
import FootSkillSlide2 from './footskill/footSkillSlide2';
import FootSkillSlide3 from './footskill/footSkillSlide3';
import FootSkillSlide4 from './footskill/footSkillSlide4';

const OldiPresentation = () => {
  return (
    <>
      <PresentationWrapper>
        <FootSkillSlide1 />
        <FootSkillSlide2 />
        <FootSkillSlide3 />
        <FootSkillSlide4 />
      </PresentationWrapper>
      <Footer />
    </>

  )

}

export default observer(OldiPresentation);

const PresentationWrapper = styled.div`

  background: #F5F5F5;
  margin:0 auto;
  display: flex;
  flex-direction:column;
  align-items: center;
`


