import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const PimsSlide5 = () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275018/Presentation%20Oldi%20Dev/ts_bbfkpg.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275017/Presentation%20Oldi%20Dev/java_fdasix.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275017/Presentation%20Oldi%20Dev/Figma_aky8z7.svg' />
        {/* <PresentationImg src='https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687424812/MobX-state-tree_pfkicm.svg' /> */}
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275017/Presentation%20Oldi%20Dev/MySQL_xrvdgd.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275017/Presentation%20Oldi%20Dev/Cloudinary_ganweo.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275018/Presentation%20Oldi%20Dev/React_Native_e73diw.svg' />
      </ContainerPresentation>
    </>

  )

}

export default observer(PimsSlide5);

const ContainerPresentation = styled.div`
  padding:200px 100px 200px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  flex-wrap:wrap;
  align-items: center;
  gap: 150px;
  max-width:1900px;
  @media screen and (max-width: 1470px){
    max-width: 1000px;
    gap: 50px;
  }
  @media screen and (max-width: 690px){
    gap: 30px;
  }

`

const PresentationImg = styled.img`
  width:auto;
  max-width:1000px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1470px){
    max-width: 150px;
  }
  @media screen and (max-width: 690px){
    max-width: 100px;
  }

`





