import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Footer from '../footer';
import PimsSlide1 from './pims/pimsSlide1';
import PimsSlide2 from './pims/pimsSlide2';
import PimsSlide3 from './pims/pimsSlide3 ';
import PimsSlide4 from './pims/pimsSlide4';
import PimsSlide5 from './pims/pimsSlide5';

const OldiPresentation = () => {
  return (
    <>
      <PresentationWrapper>
        <PimsSlide1 />
        <PimsSlide2 />
        <PimsSlide3 />
        <PimsSlide4 />
        <PimsSlide5 />
      </PresentationWrapper>
      <Footer />
    </>

  )

}

export default observer(OldiPresentation);

const PresentationWrapper = styled.div`
  background: #F5F5F5;
  margin:0 auto;
  display: flex;
  flex-direction:column;
  align-items: center;
`



