import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Footer from '../footer';
import WoodcoinSlide1 from './woodcoin/woodcoinSlide1';
import WoodcoinSlide2 from './woodcoin/woodcoinSlide2';
import WoodcoinSlide3 from './woodcoin/woodcoinSlide3';
import WoodcoinSlide4 from './woodcoin/woodcoinSlide4';
import WoodcoinSlide5 from './woodcoin/woodcoinSlide5';

const OldiPresentation = () => {
  return (
    <>
      <PresentationWrapper>
        <WoodcoinSlide1 />
        <WoodcoinSlide2 />
        <WoodcoinSlide3 />
        <WoodcoinSlide4 />
        <WoodcoinSlide5 />
      </PresentationWrapper>
      <Footer />
    </>

  )

}

export default observer(OldiPresentation);

const PresentationWrapper = styled.div`

  background: #F5F5F5;
  margin:0 auto;
  display: flex;
  flex-direction:column;
  align-items: center;
`


