import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const WoodcoinSlide5 = () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685303863/Presentation%20Oldi%20Dev/vpn/TypeScript_mhuhbr.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275018/Presentation%20Oldi%20Dev/React_Native_e73diw.svg' />
        {/* <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275017/Presentation%20Oldi%20Dev/MobX-state-tree_z5r3cp.svg' /> */}
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685308951/woodcoin/%D0%A1_kqg1ep.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685308951/woodcoin/BitCore_mn92xv.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275017/Presentation%20Oldi%20Dev/Figma_aky8z7.svg' />
      </ContainerPresentation>
    </>

  )

}

export default observer(WoodcoinSlide5);

const ContainerPresentation = styled.div`
  padding:200px 100px 200px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  flex-wrap:wrap;
  align-items: center;
  gap: 150px;
  max-width:1300px;
  @media screen and (max-width: 1470px){
    max-width: 1000px;
    gap: 50px;
  }
  @media screen and (max-width: 690px){
    gap: 30px;
  }
`

const PresentationImg = styled.img`
  width:auto;
  max-width:1000px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1470px){
    max-width: 150px;
  }
  @media screen and (max-width: 690px){
    max-width: 100px;
  }

`





