import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import Footer from '../footer';
import JustEatSlide1 from './justEat/justEatSlide1';
import JustEatSlide2 from './justEat/justEatSlide2';
import JustEatSlide3 from './justEat/justEatSlide3';
import JustEatSlide4 from './justEat/justEatSlide4';

const OldiPresentation = () => {
  return (
    <>
      <PresentationWrapper>
        <JustEatSlide1 />
        <JustEatSlide2 />
        <JustEatSlide3 />
        <JustEatSlide4 />
      </PresentationWrapper>
      <Footer />
    </>

  )

}

export default observer(OldiPresentation);

const PresentationWrapper = styled.div`

  background: #F5F5F5;
  margin:0 auto;
  display: flex;
  flex-direction:column;
  align-items: center;
`


