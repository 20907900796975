export const ourPrize = [
  {
    role: "Привет",
    prize: [
      {
        vector: "https://res.cloudinary.com/dmxoqnqsu/image/upload/v1686949146/Vector_1_a5v3cr.svg",
        title: "Призовые места в хакатонах (2018-2020 года) и IT-турнирах различной сложности по всей России.",
      },
      {
        vector: "https://res.cloudinary.com/dmxoqnqsu/image/upload/v1686949146/Vector_1_a5v3cr.svg",
        title: "Высокая клиентоориентированность.",
      },
      {
        vector: "https://res.cloudinary.com/dmxoqnqsu/image/upload/v1686949146/Vector_1_a5v3cr.svg",
        title: "Более 100 довольных заказчиков, выполненной работой в заданные сроки.",
      },
      {
        vector: "https://res.cloudinary.com/dmxoqnqsu/image/upload/v1686949146/Vector_1_a5v3cr.svg",
        title: "Быстрое и качественное выполнение поставленных целей и задач.",
      },
      {
        vector: "https://res.cloudinary.com/dmxoqnqsu/image/upload/v1686949146/Vector_1_a5v3cr.svg",
        title: "Разработка проектов для крупных заказчиков, что позволило привлечь компании - партнеры.",
      },
      {
        vector: "https://res.cloudinary.com/dmxoqnqsu/image/upload/v1686949146/Vector_1_a5v3cr.svg",
        title: "Эффективность. Мы поможем оптимизировать процессы и автоматизировать задачи, что может сэкономить время и повысить производительность.",
      },
    ],
  }
]