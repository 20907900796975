import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const BitstorageSlide5 = () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685314490/vue_yr6bra.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685308042/Presentation%20Oldi%20Dev/footskill/js_l4evld.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685314489/zabbix_gftdjz.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685275017/Presentation%20Oldi%20Dev/MySQL_xrvdgd.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685305942/Presentation%20Oldi%20Dev/just%20eat/nodejs_vvzpjk.svg' />
        <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685314932/cloudFlare_f41x8x.svg' />
      </ContainerPresentation>
    </>

  )

}

export default observer(BitstorageSlide5);

const ContainerPresentation = styled.div`
  padding:200px 100px 200px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  flex-wrap:wrap;
  align-items: center;
  gap: 150px;
  max-width:1200px;
  @media screen and (max-width: 1470px){
    max-width: 1000px;
    gap: 50px;
  }
  @media screen and (max-width: 690px){
    gap: 30px;
  }
`

const PresentationImg = styled.img`
  width:auto;
  max-width:1000px;
  display: flex;
  justify-content: center;
  @media screen and (max-width: 1470px){
    max-width: 150px;
  }
  @media screen and (max-width: 690px){
    max-width: 100px;
  }
`





