import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const GrainrusSlide4 = () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationItem>
          <PresentationImg src='https://res.cloudinary.com/dvuulrr1v/image/upload/v1685315609/grainrusslide4_crj7bs.svg' />
          <PresentationText>
            <PresentationTextContent>
              <Point src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687096233/%D0%B3%D0%B0%D0%BB%D0%BE%D1%87%D0%BA%D0%B0_esuy0n.svg" alt="point" />
              Поставщик указывает: параметры качества продукции, точки отгрузки, сроки поставки
            </PresentationTextContent>
            <PresentationTextContent>
              <Point src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687096233/%D0%B3%D0%B0%D0%BB%D0%BE%D1%87%D0%BA%D0%B0_esuy0n.svg" alt="point" />
              Клиент может запросить пробы конкретной партии продукции
            </PresentationTextContent>
            <PresentationTextContent>
              <Point src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687096233/%D0%B3%D0%B0%D0%BB%D0%BE%D1%87%D0%BA%D0%B0_esuy0n.svg" alt="point" />
              Стороны могут согласовать индивидуальную цену и условия поставки
            </PresentationTextContent>
          </PresentationText>

        </PresentationItem>
      </ContainerPresentation>
    </>

  )

}

export default observer(GrainrusSlide4);

const Point = styled.img`
  width: 24px;
  height: 24px;
`;


const ContainerPresentation = styled.div`
  padding:50px 16px 20px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  align-items: center;
  flex-direction:column;
  max-width:1900px;
`

const PresentationItem = styled.div`
margin-top:60px ;
display: flex;
justify-content: space-between;
align-items:center;
gap: 50px;
@media screen and (max-width: 1470px){
  flex-direction: column-reverse;
}
`
const PresentationImg = styled.img`
  width: 100%;
  object-fit:cover;
  max-width:1000px;
  display: flex;
  justify-content: center;

`
const PresentationText = styled.div`
  max-width:965px;
  display: flex;
  flex-direction:column;
  gap: 30px;
`
const PresentationTextContent = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 44px;
  color: #333333;
  display: flex;
  align-items: center;
  gap: 10px;
  @media screen and (max-width: 690px){
    font-size: 20px;
    line-height: 24px;
  }
`





