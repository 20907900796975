import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';


const VPNSlide2 = () => {
  return (
    <>
      <ContainerPresentation>
        <PresentationItem>
          <PresentationImg src='https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687433603/Group_1697_ha2uaw.svg' />
          <PresentationText>
            <PresentationTitle>VPN WORLD</PresentationTitle>
            <PresentationTextContent>
              Актуальность VPN приложений растет с каждым днем. Мы подумали о том, что рядовому обывателю не нужна нагрузка в виде настроек сервера и другого функционала. Просто свайп вниз и Вы онлайн!
            </PresentationTextContent>
            <PresentationTextContent>
              Наш сервис прост в использовании и доступен на всех устройствах. Мы создали с нуля мобильное приложение под Android и iOS, также реализовали сборки под Windows, MacOs, Linux. Сервис сейчас полностью бесплатный на всех платформах. Средняя задержка интернет соединения не превышает 9 мс. Мы шифруем интернет соединение и никуда не передаем данные пользователей.
              Решение разрабатывалось нами в течение 22-ух календарных дней.
            </PresentationTextContent>
            <PresentationTextContent>
              Сейчас вы можете скачать результаты нашей работы в плэй-маркетах вашего телефона или на сайте: vpn.oldi.dev
            </PresentationTextContent>
          </PresentationText>
        </PresentationItem>
      </ContainerPresentation>
    </>

  )

}

export default observer(VPNSlide2);

const ContainerPresentation = styled.div`
  padding:50px 16px 20px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  align-items: center;
  flex-direction:column;
  max-width:1900px;
`

const PresentationItem = styled.div`
/* padding-top:130px ; */
display: flex;
justify-content: space-between;
align-items:center;
gap: 50px;
@media screen and (max-width: 1470px){
  flex-direction: column-reverse;
}
`
const PresentationImg = styled.img`
    width: 100%;
  object-fit:cover;
  max-width:1000px;
  display: flex;
  justify-content: center;

`
const PresentationText = styled.div`
  max-width:  1100px;
  display: flex;
  width: calc(100% - 80px);
  flex-direction:column;
  gap: 30px;
`
const PresentationTitle = styled.div`
  font-family: 'InterSemiBold';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 34px;
  color: #333333;
    display: flex;
  justify-content: center;
`
const PresentationTextContent = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 24px;
  line-height: 44px;
  color: #333333;
`

