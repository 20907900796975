import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import PulseLoader from 'react-spinners/PulseLoader';
import { useState, useEffect } from 'react';
import axios from 'axios';


const OldiSlide1 = () => {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true)
    axios
      .get('https://res.cloudinary.com/dvuulrr1v/image/upload/v1685314491/bitstorageslide1_i5a0at.svg')
      .then(() => {
        setLoading(false)
      })
  }, [])

  return (
    <>
      {
        loading ?
          <LoadStyle>
            <PulseLoader
              speedMultiplier={0.3}
              color={'black'}
              loading={loading}
              size={30}
              aria-label="Loading Spinner"
              data-testid="loader"
            />
          </LoadStyle>
          :
          <ContainerPresentation>
            <PresentationImg src="https://res.cloudinary.com/dmxoqnqsu/image/upload/v1687105149/Group_1708_ylpew9.png" />
          </ContainerPresentation>
      }
    </>

  )

}

export default observer(OldiSlide1);

const LoadStyle = styled.div`
display: flex;
justify-content: center;
align-items: center;
height:110vh;
`

const ContainerPresentation = styled.div`
  margin-top: 80px;
  padding:50px 16px 20px;
  background: #F5F5F5;
  display: flex;  
  justify-content: center;
  align-items: center;
  flex-direction:column;
  max-width:1900px;
`

const PresentationImg = styled.img`
  /* min-height:100vh; */
  max-width:1900px;
  display: flex;
  justify-content: center;
  width: 100%;
  /* object-fit:cover; */
  @media screen and (max-width: 1470px){
  max-width: 1000px;
  
  }
  @media screen and (max-width: 690px){
    
  
}

`